<template>
  <div style="text-align: left">课程列表</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="课程分类" size="small" style="margin-left: 20px">
      <el-cascader
        v-model="seachForm.categoryId"
        :props="props"
        :show-all-levels="false"
        :options="categoryOption"
      ></el-cascader>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="clearall"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <el-button type="primary" size="mini" @click="addgood">创建课程</el-button>
  </div>
  <el-table
    :data="tableData"
    @expand-change="expanditem"
    row-key="id"
    :expand-row-keys="expandarr"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column type="expand">
      <template #default="props">
        <el-button type="primary" size="mini" @click="addvideo(props)"
          >添加视频</el-button
        >
        <el-table
          v-if="props.row.videosList && props.row.videosList.length > 0"
          :data="props.row.videosList"
          style="width: 100%"
        >
          <el-table-column prop="title" label="标题" width="180">
          </el-table-column>
          <el-table-column prop="content" label="简介" width="180">
          </el-table-column>
          <el-table-column label="视频链接" width="180">
            <template #default="scope">
              <a
                :href="videoBaseUrl + scope.row.videoUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <el-tag>点击查看</el-tag>
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="创建日期">
          </el-table-column>
          <el-table-column prop="createDate" label="操作">
            <template #default="scope">
              <el-button type="text" @click="editvideo(scope.row, props)"
                >编辑</el-button
              >
              <el-button type="text" @click="deletevideo(scope.row,props)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table-column>
    <el-table-column prop="title" label="课程名称" width="180">
    </el-table-column>
    <el-table-column prop="liveUrl" label="直播上架地址" width="180">
    </el-table-column>
    <el-table-column prop="imgUrl" label="课程封面" width="180">
      <template #default="scope">
        <img
          v-if="scope.row.pictureUrl1"
          :src="imgBaseUrl + scope.row.pictureUrl1"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column prop="categoryId" label="课程分类" width="180">
      <template #default="scope">
        <span>{{ findname(scope.row.categoryId) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="专家" width="180">
      <template #default="scope">
        <el-tag type="success" style="margin-left: 10px"  v-if="scope.row.expertNames[0]">{{
          scope.row.expertNames[0]
        }}</el-tag>
        <el-tag
          type="success"
          style="margin-left: 10px; margin-top: 10px"
          v-if="scope.row.expertNames[1]"
          >{{ scope.row.expertNames[1] }}</el-tag
        >
        <el-tag
          type="success"
          style="margin-left: 10px; margin-top: 10px"
          v-if="scope.row.expertNames[2]"
          >{{ scope.row.expertNames[2] }}</el-tag
        >
        <el-tag
          type="success"
          style="margin-left: 10px; margin-top: 10px"
          v-if="scope.row.expertNames.length > 3"
          >...</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column prop="courseClass" label="课程价格(划线/售价)" width="180">
      <template #default="scope">
        <span>{{ scope.row.basePrice + "/" + scope.row.salePrice }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="showDate" label="发布时间"> </el-table-column>
    <el-table-column prop="topped" label="是否推荐">
         <template #default="scope">
        <el-tag type="success" v-if="scope.row.topped">推荐</el-tag>
        <!-- <el-tag type="success" v-if="scope.row.topped">推荐</el-tag> -->
      </template>
    </el-table-column>
    <el-table-column prop="goodStatus" label="操作">
      <template #default="scope">
        <el-button @click="recmdcourse(scope.row)" type="text" size="mini"
          >推荐</el-button
        >
        <el-button @click="editcourse(scope.row)" type="text" size="mini"
          >编辑</el-button
        >
        <el-button @click="deletecourse(scope.row)" type="text" size="mini"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页组件 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10,15,20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
  <!-- 对话框 -->
  <el-dialog title="视频信息" v-model="dialogVisible" width="600px">
    <el-form ref="form" :model="videoForm" label-width="80px">
      <el-form-item label="视频标题" size="small">
        <el-input
          v-model="videoForm.title"
          clearable
          placeholder="请添加视频标题！"
        ></el-input>
      </el-form-item>
      <el-form-item label="视频简介" size="small">
        <el-input
          v-model="videoForm.content"
          clearable
          placeholder="请添加视频简介！"
        ></el-input>
      </el-form-item>
      <el-form-item label="视频上传" size="small">
         <div v-if="!uploadershow" style="display:flex">
              <el-button type="text" plain size="medium" @click="getvideo">查看当前视频</el-button>
              <el-button type="text" plain size="medium" @click="reloadvideo">重新上传视频</el-button>
          </div>
        <uploader :options="options" v-if="uploadershow"  class="uploader-example" @fileProgress="onfileprogress"  :file-status-text="statusText" ref="uploader" @file-complete="fileComplete" @complete="complete" >
          <uploader-unsupport></uploader-unsupport>
          <!-- <uploader-drop> -->
            <!-- <p>将文件拖动到此处上传文件</p> -->
            <uploader-btn :single="true" :attrs="attrs">点击选取文件</uploader-btn>
          <!-- </uploader-drop> -->
          <uploader-file v-if="Object.keys(upfile).length!==0" :timeRemaining="timeRemaining" :averageSpeed="averageSpeed" :currentSpeed="currentSpeed" :iscom="isComplete"  :progress="upProgress" :file="upfile" ></uploader-file>
          <!-- <uploader-list></uploader-list> -->

        </uploader>
        <!-- <span v-if="isprogress" style="color:#F56C6C;margin-top:10px;font-weight:600">正在上传请等待...</span> -->
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitvideo" :disabled="isprogress"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import uploader from '../../common/uploader/components/uploader.vue'
// import _ from 'lodash'
export default {
  components: { uploader },
  data() {
    return {
      tableData: [],
      seachForm: {
        categoryId: [],
      },
      key: Math.random(),
      categoryOption: [],
      professionOption: [],
      baseUrl: '',
      dialogVisible: false,
      courseId: '',
      videoForm: {
        title: '',
        content: '',
        videoUrl: '',
        classId: '',
      },
      videoFileList: [],
      filechangeList: [],
      formurl: [],
      isaddvideo: true,
      vidTableData: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
      props: {
        value: 'id',
        label: 'name'
      },
      iserror: false,
      expandarr: [],
      isprogress: false,
      options: {
        // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
        target: '',
        testChunks: false, // 上传前判断块是否已经存在
        simultaneousUploads: 5, // 并发数
        chunkSize: 8 * 1024 * 1024, // 块大小
        // singleFile: true
        // headers: {
        //   authorization: "vg0mc"
        // },
      },
      statusText: {
          success: '成功了',
          error: '出错了',
          uploading: '上传中',
          paused: '暂停中',
          waiting: '等待中'
      },
      attrs: {
        accept: 'video/*'
      },
      uploadershow: true,
      upfile:{},
      upProgress: -1,
      isComplete: false,
      averageSpeed: -1,
      currentSpeed: -1,
      timeRemaining: -1
    }
  },
  methods: {
    async handleSizeChange(val) {
      const res = await this.$http.get('/nktdec/classinfo/classinfos/1' + '?pageSize=' + val + (this.seachForm.categoryId.length === 0 ? '' : '&categoryId=' + this.seachForm.categoryId[1]))
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setcourseSeachForm',{...this.courseSeachForm , currentpage : '1' , pageSize: val}) 
    },
    async handleCurrentChange(val) {
      var str = val + '?pageSize=' + this.pageSize + (this.seachForm.categoryId.length === 0  ? '' : '&categoryId=' + this.seachForm.categoryId[1])
      console.log(str)
      const res = await this.$http.get('/nktdec/classinfo/classinfos/' + str)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = val
      this.$store.commit('setcourseSeachForm',{...this.courseSeachForm , currentpage : val , pageSize: this.pageSize })
    },
    query(str,obj){
      if(obj.currentpage){
           str+= obj.currentpage+'?'
           for(let i in obj){
            if(i==='currentpage') {
                continue
            }else{
              if(i==='categoryId' && obj[i][1]){
                str+='categoryId='+ obj[i][1]
                continue
              }else if(obj[i]!==''){
                  str+='&'+i+'='+ obj[i]
                }
              }
         }
      }else{
        str+='1'
      }
      return str 
    },
    async getcategory() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos?type=CLASS')
      this.categoryOption = res.data
      this.categoryOption.forEach(item => {
        if (item.children.length === 0) {
          this.iserror = true
        }
        item.children.forEach(itemitem => delete itemitem.children)
      })
      if (this.iserror) {
        this.$message.error('请确保课程分类均为二级！')
        this.iserror = false
      }
    },
    async onSubmit() {
      var str = '?' + (this.seachForm.categoryId[1].trim() === '' ? '' : 'categoryId=' + this.seachForm.categoryId[1])
      const res = await this.$http.get('/nktdec/classinfo/classinfos/1' + str)
      res.data.content.forEach(async (item) => {
        item.videosList = []
        const result = await this.$http.get('/nktdec/classinfo/classinfo/' + item.id)
        item.videosList = result.data.videoInfos
      })
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      console.log(this.seachForm)
      this.$store.commit('setcourseSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
    },
    async getprofession() {
      const res = await this.$http.get('/nktdec/expertinfo/expertinfos/1')
      this.professionOption = res.data.content
    },
    findname(id) {
      var arr = this.categoryOption.filter(item => {
        var i = item.children.findIndex(itemitem => itemitem.id === id)
        if (i !== -1) {
          return item
        }
      })
      var j = arr[0].children.findIndex(item => item.id === id)
      // console.log(arr[0].children[j].name)
      return arr[0].children[j].name
    },
    async getcourseInfo(obj) {
      console.log(obj)
      var str = this.query('',obj)
      console.log(str)
      if(obj.categoryId){
        this.seachForm.categoryId = obj.categoryId
      }
      const res = await this.$http.get('/nktdec/classinfo/classinfos/' + str)
      // console.log(res);
      res.data.content.forEach(async (item) => {
        item.videosList = []
        const result = await this.$http.get('/nktdec/classinfo/classinfo/' + item.id)
        // console.log(res.data.videoInfos)
        item.videosList = result.data.videoInfos
      })
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = obj.currentpage - 0
      this.$store.commit('setcourseSeachForm',{...this.courseSeachForm , currentpage : obj.currentpage , pageSize: '20'})
    },
    async clearall() {
      const res = await this.$http.get('/nktdec/classinfo/classinfos/1')
      res.data.content.forEach(async (item) => {
        item.videosList = []
        const result = await this.$http.get('/nktdec/classinfo/classinfo/' + item.id)
        item.videosList = result.data.videoInfos
      })
      this.seachForm.categoryId = []
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.$store.commit('setcourseSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
    },
    async changeshowstate(row) {
      row.adopted = !row.adopted
      const res = await this.$http.post('/nktdec/bannerinfo/bannerinfos', row)
      if (res.data.code === '1') this.$message.success('设置成功！')
      this.getcourseInfo(this.courseSeachForm)
    },
    editcourse(row) {
      console.log(row);
      window.sessionStorage.setItem('CourseInfo', JSON.stringify(row))
      this.$store.commit('setCourseInfo', row)
      this.$router.push('/addcourses/false')
    },
    async submitvideo() {
      this.uploadershow = false
      const data = {}
      if (this.isaddvideo) {
        data.classId = this.courseId
        data.title = this.videoForm.title
        data.content = this.videoForm.content
      } else {
        data.id = this.videoForm.id
        data.classId = this.courseId
        data.title = this.videoForm.title
        data.content = this.videoForm.content
      }
      // for (let i = 0; i < this.formurl.length; i++) {
        data.videoUrl =  this.videoForm.videoUrl
        const res = await this.$http.post('/nktdec/classinfo/videoinfos', data)
      if (res.data.code === '0') {
        return this.$message.error('请确认是否填写完整！')
      }
       var obj = this.tableData.find(item => this.courseId === item.id)
      console.log(obj)
      if (this.isaddvideo) {
          obj.videosList.push(res.data.result)
      } else {
         var index = obj.videosList.findIndex(item => item.id === res.data.result.id)
         obj.videosList.splice(index, 1, res.data.result)
      }
      this.$message.success('保存成功！')
      this.dialogVisible = false
      // setTimeout(() => {
      // }, 0)
    },
    addvideo(props) {
      console.log(props)
      this.courseId = props.row.id
      this.isaddvideo = true
      this.dialogVisible = true
      this.videoForm.title = ''
      this.videoForm.content = ''
      this.videoForm.videoUrl = ''
      this.videoForm.classId = ''
      this.upfile = {}
      this.uploadershow = true
      this.videoFileList = []
      // console.log(this.$refs)
      // window.uploader = this.$refs.uploader.uploader()
    },
    async recmdcourse(row) {
      console.log(row)
      row.topped = !row.topped
      const res = await this.$http.post('/nktdec/classinfo/classinfos', {
        ...row
      })
      if (res.data.code === '1') {
        this.$message.success('修改成功！')
      }
      console.log(res)
    },
    editvideo(row, props) {
      console.log(row)
      console.log(props)
      this.isComplete = false
      this.courseId = props.row.id
      this.dialogVisible = true
      this.isaddvideo = false
      this.videoForm = row
      this.uploadershow = false
      console.log(this.videoForm)
      this.videoFileList = []
      // if () {
      // this.videoFileList.push({
      //   name: row.title,
      //   url: this.videoBaseUrl + row.videoUrl
      // })
      // }
      // this.upfile =
    },
    handremove(file) {
      console.log(file)
      console.log(this.videoFileList)
      var i = this.videoFileList.findIndex(item => item.url === file.url)
      this.videoFileList.splice(i, 1)
    },
    async expanditem(row, state) {
      console.log(row)
      if (this.expandarr.indexOf(row.id) === -1) {
         this.expandarr.push(row.id)
      } else {
        this.expandarr.splice(this.expandarr.indexOf(row.id), 1)
      }
    },
    async deletecourse(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该课程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/classinfo/classinfo/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getcourseInfo(this.courseSeachForm)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
    addgood() {
      this.$router.push('/addcourses/true')
    },
    async deletevideo(row, props) {
      console.log(row, props)
      this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/classinfo/videoinfo/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          var obj = this.tableData.find(item => item.id === props.row.id)
          var i = obj.videosList.findIndex(item => item.id === row.id)
          obj.videosList.splice(i, 1)
        }
      })
    },

    // 多线程上传视频
    complete() {
      console.log('complete', arguments)
    },
    fileComplete() {
      console.log('file complete', arguments)
      const file = arguments[0].file
      // this.upfile = arguments[0].file
      const url = '/uploader/chunkfile/merge'
      const params = {
        fileName: file.name,
        guid: arguments[0].uniqueIdentifier,
        fileType: 'video'
      }
      var that = this
      this.$http.post(url, params).then(function(res) {
        console.log(res.data)
        if(res.data.code === '1'){
          that.videoForm.videoUrl = res.data.result
          that.$message.success('上传成功！')
        }
        // response
      }).catch(function(error) {
        console.log(error)
      })
    },
    onfileprogress(e){
      console.log(e)
      // return
      e._measureSpeed()
      this.upfile = e
      this.upProgress = e.progress()
      this.averageSpeed = e.averageSpeed
      this.currentSpeed = e.currentSpeed
      this.timeRemaining = e.timeRemaining()
      this.isComplete = e.isComplete()
      console.log('+++++++++++++++',e,e.timeRemaining(),e.averageSpeed)
    },
    getvideo() {
      window.open(this.videoBaseUrl + this.videoForm.videoUrl)
    },
    reloadvideo() {
      this.uploadershow = !this.uploadershow
      this.upfile = {}
      this.videoForm.videoUrl = ''
      this.isComplete = false
    }
  },
  created() {
    this.options.target = this.$http.defaults.baseURL + '/uploader/chunkfile/upload'
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '课程管理',
        index: '/courselist'
      },
      {
        name: '课程列表',
        index: '/courselist'
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.baseUrl = this.$http.defaults.baseURL
    this.getcategory()
    this.getprofession()
      .then(() => {
        this.getcourseInfo(this.courseSeachForm)
      })
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
     videoBaseUrl() {
      return this.$store.state.videoBaseUrl
    },
    courseSeachForm() {
      return this.$store.state.courseSeachForm
    },
  },
  mounted() {
        // this.$nextTick(() => {
        //   console.log(this.$refs.uploader)
        //   // window.uploader = this.$refs.uploader.uploader()
        // })
  }
}
</script>
<style scoped>
.lineform {
  display: flex;
  justify-content: flex-start;
}
.expendform {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.expendform .el-form-item {
  width: 48%;
}
</style>
