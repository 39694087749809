<template>
  <div style="text-align: left">创建课程</div>

  <el-form
    :model="addcourseForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item label="课程名称" size="small" prop="title" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addcourseForm.title"
          clearable
          placeholder="请输入课程名称"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="课程分类"
      size="small"
      prop="goodClass"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-cascader
          v-model="addcourseForm.categoryId"
          :props="props"
          :show-all-levels="false"
          :options="categoryOption"
          @change="handleChange"
        ></el-cascader>
      </div>
    </el-form-item>
    <el-form-item
      label="是否免费"
      size="small"
      prop="basePrice"
      style="width: 50%"
    >
      <div class="displayflex" style="align-items: center;height: 32px;">
        <el-switch
          v-model="addcourseForm.isfree"
        ></el-switch>
      </div>
    </el-form-item>
    <el-form-item
      label="划线价格"
      size="small"
      prop="basePrice"
      style="width: 50%"
    >
      <div class="displayflex">
        <el-input
          v-model="addcourseForm.basePrice"
          :disabled="addcourseForm.isfree"
          clearable
          placeholder="请输入划线价格！"
          style="width: 150px"
        ></el-input>
        <span style="margin-left: 15px">元</span>
      </div>
      <div style="text-align: left">
        <span style="color: rgba(0, 0, 0, 0.45)"
          >用于展示的划线价格，最低0.01</span
        >
      </div>
    </el-form-item>
    <el-form-item
      label="课程售价"
      size="small"
      prop="salePrice"
      style="width: 50%"
    >
      <div class="displayflex">
        <el-input
          v-model="addcourseForm.salePrice"
           :disabled="addcourseForm.isfree"
          clearable
          placeholder="请输入课程售价！"
          style="width: 150px"
        ></el-input>
        <span style="margin-left: 15px">元</span>
      </div>
      <div style="text-align: left">
        <span style="color: rgba(0, 0, 0, 0.45)">课程的销售价格，最低0.01</span>
      </div>
    </el-form-item>
     <el-form-item
      label="已售数量"
      size="small"
      prop="salePrice"
      style="width: 50%"
    >
      <div class="displayflex">
        <el-input
          v-model="addcourseForm.saledNumber"
           :disabled="addcourseForm.isfree"
          clearable
          placeholder="请输入已售数量！"
          style="width: 150px"
        ></el-input>
      </div>
    </el-form-item>
     <el-form-item  label="免费时长" size="small" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addcourseForm.freeNumber"
           :disabled="addcourseForm.isfree"
          clearable
          placeholder="请输入免费时长"
          style="width: 150px"
        ></el-input>
        <span style="margin-left: 10px">秒</span>
      </div>
    </el-form-item>
    <el-form-item
      label="显示时间"
      size="small"
      prop="showDate"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-date-picker
          v-model="addcourseForm.showDate"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </div>
    </el-form-item>
    <el-form-item label="专家选取" prop="goodClass" style="width: 50%">
      <div style="display: flex; flex-wrap: wrap; width: 350px">
        <el-checkbox-group v-model="addcourseForm.experts">
          <el-checkbox
            v-for="item in professionOption"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
        <!-- <el-select
          v-model="addcourseForm.experts"
          style="width: 150px"
          placeholder="请选择课程分类！"
        >
          <el-option
            v-for="item in professionOption"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select> -->
      </div>
    </el-form-item>
    <el-form-item
      label="课程图片"
      prop="coursePic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
        :action="UploadUrl"
        :headers="{
          authorization: Token
        }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="true"
        :before-upload="handlebeforupload"
        accept=""
        :file-list="cousepicList"
        :on-success="filechange"
        :limit="5"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file, fileList)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比8：5、图片大小200k为宜, 最多上传5张,单张图片不超过2M</span
      >
    </el-form-item>
    <el-form-item
      label="课程描述"
      size="small"
      prop="summary"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          v-model="addcourseForm.summary"
          clearable
          placeholder="请输入课程描述！"
          style="width: 230px"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="课程详情" style="width: 80%">
      <vue-ueditor-wrap
        v-model="addcourseForm.content"
        :config="editorConfig"
        editor-id="courseInfo"
      ></vue-ueditor-wrap>
    </el-form-item>
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      addcourseForm: {
        title: '',
        categoryId: '',
        summary: '',
        content: '',
        pictureUrl1: '',
        pictureUrl2: '',
        pictureUrl3: '',
        pictureUrl4: '',
        pictureUrl5: '',
        experts: [],
        videos: [],
        basePrice: '',
        salePrice: '',
        freeNumber: '',
        showDate: '',
        isfree: false,
        saledNumber: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入课程题目！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: '请至少选取一个课程分类！', trigger: 'blur' }
        ],
        summary: [
          { required: true, message: '请输入课程概括！', trigger: 'blur' }
        ],
        basePrice: [
          { required: true, message: '请输入划线价格！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        salePrice: [
          { required: true, message: '请输入售卖价格！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        goodStatus: [
          { required: true, message: '请输入商品状态！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        goodsPic: [
          { required: true, message: '请输入选择商品图片！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      content: '<p>666777</p>',
      activeName: 'first',
      commonForm: {
        heightPrice: 100,
        Price: 80,
        freeTimes: 3,
        time: '2021-05-08 00:00:00'
      },
      commonRules: {
        heightPrice: [
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        Price: [
          { required: true, message: '请输入价格！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        freeTimes: [
          { required: true, message: '请输入免费观看次数！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        time: [
          { required: true, message: '请选择发布时间！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      categoryOption: [],
      professionOption: [],
      cousepicList: [],
      corsepicTemArr: [],
      baseUrl: '',
      props: {
        value: 'id',
        label: 'name'
      },
      uploadUrl: '',
    }
  },
  methods: {
        handlebeforupload(file){
      return new Promise (async(resolve, reject)=>{
          const isLt2M = file.size / 1048576  < 2;
          if(!isLt2M && file.type.indexOf('image')!==-1){
              this.$message.error('上传图片请小于2M')
              reject()
          }else{
            resolve()
          }
      })
    },
    handleexceed() {
      this.$message.error('仅限上传5个媒体文件！')
    },
    handleRemove(file) {
      console.log(file)
      var i = this.cousepicList.findIndex(item => item.url === file.url)
      // var arr = this.cousepicList.filter(item => item.url !== file.url)
      // this.cousepicList = arr
      console.log(i)
      this.cousepicList.splice(i, 1)
      console.log(file.url.split('/'))
      var tmpArr = file.url.split('/')
      var j = this.corsepicTemArr.findIndex(item => item === tmpArr[tmpArr.length - 1])
      this.corsepicTemArr.splice(j, 1)
      console.log('绑定数组', this.cousepicList)
      console.log('拼接数组', this.corsepicTemArr)
    },
    async onsubmit() {
      console.log(this.addcourseForm)
      console.log(this.corsepicTemArr)
      console.log(Array.isArray(this.corsepicTemArr))
      if (this.corsepicTemArr.length === 0 || this.corsepicTemArr[0].trim() === '') {
        this.$message.error('请上传图片！')
        return
      }
      const data = {}
      const nameStr = []
      this.addcourseForm.experts.forEach(item => {
        var a = this.professionOption.find(proitem => {
          console.log(proitem, item)
          return proitem.id === item
        })
        console.log(this.professionOption)
        if (a) {
            nameStr.push(a.name)
        }
        console.log(nameStr)
      })
      console.log(this.addcourseForm.experts.join(',') + '_' + nameStr.join(','))
      if (this.$route.params.isadd === 'true') {
        data.categoryId = this.addcourseForm.categoryId[1]
        data.content = this.addcourseForm.content
        if (this.addcourseForm.experts && this.addcourseForm.experts.length > 0) {
          data.experts = this.addcourseForm.experts.join(',') + '_' + nameStr.join(',')
        }
        data.title = this.addcourseForm.title
        data.showDate = this.formatdate(this.addcourseForm.showDate)
        data.summary = this.addcourseForm.summary
        data.pictureUrl1 = this.corsepicTemArr[0] || ''
        data.pictureUrl2 = this.corsepicTemArr[1] || ''
        data.pictureUrl3 = this.corsepicTemArr[2] || ''
        data.pictureUrl4 = this.corsepicTemArr[3] || ''
        data.pictureUrl5 = this.corsepicTemArr[4] || ''

        data.topped = false
        if (this.addcourseForm.isfree) {
            data.basePrice = 0
            data.salePrice = 0
            data.saledNumber = 0
            data.freeNumber = 0
            // data.saledNumber =
        } else {
           data.basePrice = this.addcourseForm.basePrice
           data.salePrice = this.addcourseForm.salePrice
           data.saledNumber = this.addcourseForm.saledNumber
           data.freeNumber = this.addcourseForm.freeNumber
        }
      } else {
        data.id = this.addcourseForm.id
        // data.basePrice = this.addcourseForm.basePrice
        data.categoryId = this.addcourseForm.categoryId[1]
        data.content = this.addcourseForm.content
        if (this.addcourseForm.experts && this.addcourseForm.experts.length > 0) {
          data.experts = this.addcourseForm.experts.join(',') + '_' + nameStr.join(',')
        }
        data.freeNumber = this.addcourseForm.freeNumber
        // data.salePrice = this.addcourseForm.salePrice
        data.title = this.addcourseForm.title
        data.showDate = this.addcourseForm.showDate
        data.summary = this.addcourseForm.summary
        data.pictureUrl1 = this.corsepicTemArr[0] || ''
        data.pictureUrl2 = this.corsepicTemArr[1] || ''
        data.pictureUrl3 = this.corsepicTemArr[2] || ''
        data.pictureUrl4 = this.corsepicTemArr[3] || ''
        data.topped = false
        data.pictureUrl5 = this.corsepicTemArr[4] || ''
        data.topped = false
        data.saledNumber = this.addcourseForm.saledNumber
        if (this.addcourseForm.isfree) {
            data.basePrice = 0
            data.salePrice = 0
        } else {
           data.basePrice = this.addcourseForm.basePrice
           data.salePrice = this.addcourseForm.salePrice
        }
      }
      console.log(data)
      const res = await this.$http.post('/nktdec/classinfo/classinfos', data)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('编辑课程成功！')
        this.$router.push('/coursemange')
      }
    },
    openuploadvideo() {
      this.uploadvideo = true
    },
    handleChange(node) {
      console.log(node)
      console.log(this.addcourseForm.categoryId)
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
    async getcategory() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos?type=CLASS')
      this.categoryOption = res.data
      this.categoryOption.forEach(item => {
        if (item.children.length === 0) {
          this.$message.error('请确保课程分类均为二级！')
        }
        item.children.forEach(itemitem => delete itemitem.children)
      })
      console.log(this.categoryOption)
    },
    async getprofession() {
      const res = await this.$http.get('/nktdec/expertinfo/expertinfos/1')
      this.professionOption = res.data.content
      console.log(this.professionOption)
    },
    async filechange(res,file, fileList) {
      console.log(res)

      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      // console.log(file)

      // this.cousepicList = fileList
      this.cousepicList.push({
        url: this.imgBaseUrl + res.result
      })
      console.log(this.cousepicList)
      this.corsepicTemArr.push(res.result)
      console.log(this.corsepicTemArr)
    },
    findname(id) {
      console.log(this.categoryOption)
      var arr = this.categoryOption.filter(item => {
        // console.log(item.children)
        // console.log(id)
        var i = item.children.findIndex(itemitem => itemitem.id === id)
        // console.log(i)
        if (i !== -1) {
          return item
        }
      })
      var j = arr[0].children.findIndex(item => item.id === id)
      console.log(arr[0].children[j].name)
      const tmparr = []
      tmparr.push(arr[0].id)
      tmparr.push(id)
      return tmparr
    },
    getimgUrl() {
      this.tableData.forEach(async (item) => {
        const res = await this.$http.get('/nktdec/uploader/images/' + item.mediaUrl1)
        // console.log(this.baseUrl + res.config.url.substring(1))
        item.imgUrl = this.baseUrl + res.config.url.substring(1)
      })
      console.log(this.tableData)
    },
  },
  computed: {
    CourseInfo() {
      return this.$store.state.CourseInfo
    },
    editorConfig() {
      return this.$store.state.editorConfig
    },
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '课程管理',
        index: '/courselist'
      },
      {
        name: '课程列表',
        index: '/courselist'
      }, {
        name: '课程信息',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getprofession()
    this.baseUrl = this.$http.defaults.baseURL
    this.uploadUrl = this.$http.defaults.baseURL + 'nktdec/uploader/uploadfile'
    this.getcategory().then(() => {
      if (this.$route.params.isadd === 'false') {
        this.addcourseForm = this.CourseInfo
        this.addcourseForm.isfree = this.CourseInfo.free
        this.addcourseForm.categoryId = this.findname(this.addcourseForm.categoryId)
        this.addcourseForm.experts = this.CourseInfo.expertIds
        console.log(this.addcourseForm)
        const list = []
        setTimeout(async () => {
          for (let i = 1; i <= 5; i++) {
            if (this.addcourseForm['pictureUrl' + i].trim() !== '') {
              this.corsepicTemArr.push(this.addcourseForm['pictureUrl' + i])
              console.log('创建实例', this.corsepicTemArr)
              list.push({
                // url: result.data
                url: this.imgBaseUrl + this.addcourseForm['pictureUrl' + i]
              })
            } else {
              break
            }
          }
          this.cousepicList = list
        }, 0)
      }
    })
  },
}
</script>
<style scoped>
</style>
